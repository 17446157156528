import './photoSlider.scss'
import React, { useEffect , useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight,faDotCircle, faPhotoVideo } from '@fortawesome/free-solid-svg-icons'

const PopUpImg = (props) => {
 
  const [indexOf, setIndexOf] = useState();
  let images = [];
   useEffect(() => {
      let test = 0;
      if(props.img) {
       test = props.img.index;
      }
      setIndexOf(test)
    }, [props]);

  if(props.img) {
    images = props.img;
  }
 
  function closeNav2() {
    document.getElementById("myNav2").style.width = "0";
  }

  function showNext() {
    if(indexOf + 1 >= props.img.length) {
      setIndexOf(0)
    } else {
      setIndexOf(indexOf + 1)
    }
  }

  function showPrew() {
    if(indexOf - 1 < 0) {
      setIndexOf(props.img.length - 1)
    } else {
      setIndexOf(indexOf - 1)
    }
  }

  document.addEventListener('keydown', function(event){
    if(event.key === "Escape"){
      closeNav2();
    }
  });

  return ( 
    <div id="myNav2" className="overlay2">
      <span className="closebtn"  onClick={()=> closeNav2() }>X</span>
      <div className="overlay-content2">
      <h1 className='photoSliderHeader2' >
        <FontAwesomeIcon   icon={faPhotoVideo } style={{marginRight: '3px', fontSize: '1.8vw', color: '#0a3d44'}}/>
        GALLERY
      </h1>
      <div className='sliderWrapper' style={{display:'flex',alignItems: 'center', flex: '1' , width:'80%'}}>
        <div className='chevron' onClick={() => showPrew()}  >
            <FontAwesomeIcon className='sliderChevrons' icon={faChevronCircleLeft } style={{marginRight: '3px', fontSize: '1.8vw'}}/>
        </div>
        {images.map((imgSrc, index) => {
          let test = {display:'none'}
          if(index === indexOf ) {
            test = {display:'block',flex: '1',  }
          }
            return (
                <div key={index} style={test}>
                  <img className='imgToShow' src={imgSrc.img} alt="" />
                </div>
            )
          })}
          <div className='chevron'  onClick={() => showNext()}  > 
          <FontAwesomeIcon className='sliderChevrons' icon={faChevronCircleRight } style={{marginRight: '3px',fontSize: '1.8vw'}}/>
          </div>
        </div>
        <div style={{display: 'flex' ,flexWrap: 'wrap',paddingBottom: '10px'}}> 
          {images.map((imgSrc, index) => {
            let selected = {padding: '3px', cursor: 'pointer' ,color:'lightBlue', fontSize: '18px'  };
            if(index === indexOf ) {
              selected = {padding: '3px', cursor: 'pointer', color:'#63cef1', fontSize: '18px'}
            }
            return (
              <div style={selected} onClick={()=>   setIndexOf(index)} key={index}>   
                <FontAwesomeIcon icon={faDotCircle } style={selected}/>
                {/* <img src={imgSrc.img} style={{borderRadius: '5px'}} alt="" key={index} height={100} width={100} onClick={()=>   setIndexOf(index)}/> */}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default PopUpImg