import React, { useEffect , useState } from 'react';
import './css/education.scss'
import animation from './js/animation.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import {linkSpliter} from './js/textSpliter.js'

const Education = () => {
  const [count, setCount] = useState([]);
  useEffect(() => {
    fetch('http://localhost/backend/work.php')
    .then(response => response.json())
    .then(data => setCount(data.reverse())
    );
  }, []);
 
  useEffect(() => {
    animation('.test' , 'workAnimation' , '.testwrapper')
  })

  return (
    <div id="education" className="home"> 
      <div className="timeline" >
        <h2 className='educationPageHeader'> 
          <img src='./education-icon.png' alt="" height={40} width={40}/>
          <span> Education</span> 
        </h2>
        {count.map((education, index) => {
          let links = linkSpliter(education.link, education.linkName, '\n' )
          return (
            <div className="container right testwrapper" key={index} > 
              <div className="content test">
                <h4>
                  <FontAwesomeIcon icon={faGraduationCap} />
                  {' '}
                  {education.RowName}
                </h4>
                <p className='jobBody'>{education.Body}</p>
                {links}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export {Education}