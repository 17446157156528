import './progressBar.scss'

const ProgressBar = (props) => {
  let deg = 360 / 100 * props.value
  let leftBar = 0;
  let rightBar = 0;

  let skillColor = '#26fbfd';
  let gradient = 255 - 255 / 100 * props.value;
  skillColor = 'rgba(38,'+ Math.floor(gradient) + ',253)'

  if(props.value <= 50 ) {
    leftBar = deg;
  } else {
    leftBar = 180;
    rightBar = deg - 180 ;
  }

  return ( 
    <div>
      <div className="progress blue " >
        <span className="progress-right ">
          <p className='square-wrapper'>
              <span className='progress-bar square' 
                style={{transform: `rotate(${leftBar}deg)`, borderColor: skillColor}}
              >
              </span>       
          </p>
        </span>
        <span className="progress-left ">
          <p className='square-wrapper'>
              <span className="progress-bar square" 
                style={{transitionDelay: '500ms', transform: `rotate(${rightBar}deg)`, borderColor: skillColor}}
              >
              </span>
          </p>
        </span>           
        <div className="progress-value ">{props.value} %</div>
      </div>
      <p className='progressbarSkillName'>{props.name}</p>
    </div>
  )
}
export default ProgressBar