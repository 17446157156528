import './App.css';
import LeftSideBar from './components/leftSideBar/LeftSideBar.jsx'
import RightSideBar from './components/rightSideBar/RightSideBar.jsx'
function App() {
  return (
    <div className="App">
      <LeftSideBar />
      <RightSideBar />
    </div>
  );
}

export default App;


//"homepage": "https://cvportfolio.info/",