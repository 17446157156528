 
const Test= (entryTarget , toggleClass , container) => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const square2 = entry.target.querySelector(entryTarget);
      if (entry.isIntersecting) { 
        square2.classList.remove(toggleClass); 
      return;  
      }
      square2.classList.add(toggleClass);
    });
  });
  let target2 = container;
  document.querySelectorAll(target2).forEach((i) => {
    if (i) {
        observer.observe(i);
    }
  });  
}

export default Test;