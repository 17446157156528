import { Link } from "react-scroll";
import './css/navBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faCogs,faGraduationCap , faClipboardList, faHouseUser , faBriefcase } from '@fortawesome/free-solid-svg-icons'

function fontAwesome(valuee) {
  return <FontAwesomeIcon icon={valuee} />
}

function linkGenerator(icon , id , name){
  return (
    <Link to={id} spy={true} hashSpy={true} smooth={true} offset={-50} duration={500}> 
      {fontAwesome(icon)}
      <span className="linkText"> {name}</span> 
    </Link>
  )
}

function NavBar(props) {
  let navBarToggle = 'navBar displayNone';
  if(!props.show){
    navBarToggle = 'navBar';
  }
  return (
    <div className={navBarToggle}>
        {linkGenerator(faHouseUser , 'homePage' , 'Home' )}
        {linkGenerator(faClipboardList , 'work' , 'Work' )}
        {linkGenerator(faGraduationCap , 'education' , 'Education' )}
        {linkGenerator(faCogs , 'skills' , 'Skills' )}
        {linkGenerator(faBriefcase , 'portfolio' , 'Portfolio' )}
        {linkGenerator(faEnvelope , 'contacts' , 'Contacts' )}
    </div>
  )
}

export default NavBar