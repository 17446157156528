import './css/leftSideBarStyles.scss'
import LogoArea from './LogoArea.jsx'
import NavBar from './NavBar.jsx'
import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleUp,faBars } from '@fortawesome/free-solid-svg-icons'

function LeftSideBar() {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="leftSideBar">
      <LogoArea />
      <div className='navBarToggleBtn' >     
        {toggle
        ? 
        <FontAwesomeIcon onClick={()=> setToggle(!toggle)} icon={faAngleDoubleUp} />
        : 
        <FontAwesomeIcon onClick={()=> setToggle(!toggle)} icon={faBars} />
        }
      </div>
      <NavBar  show={toggle}/>
    </div>
  )
}

export default LeftSideBar