import './popUp.scss'
import PopUpImg from './photoSlider'
import React, {useEffect , useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faDotCircle, faBookReader} from '@fortawesome/free-solid-svg-icons'
import {textSpliter} from '../../views/js/textSpliter.js'


const PopUp = (props) => {  
  const [tehnologiesSplited, setTehnologiesSplited] = useState([]);
  const [Responsibilities, setResponsibilities] = useState([]);
  const [splitedDescription, setSplitedDescription] = useState([]);
  
  useEffect(() => {
    if(props.propa.Responsibilities) {
      setResponsibilities(props.propa.Responsibilities.split('\n'));
      setSplitedDescription(textSpliter(props.propa.Body, '\n'));
      setTehnologiesSplited(props.propa.Technologies.split(','));
    }
  }, [props]);

  let images = [];
  if(props.propa.imgLink) {
    images = JSON.parse(props.propa.imgLink); 
  }
  
  const [image, setImage] = useState();
  
  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }
  
  function showimg(img , index) {
    img.index = index;
    document.getElementById("myNav2").style.width = "100%";
    setImage(img)
  }

  document.addEventListener('keydown', function(event){
    if(event.key === "Escape"){
      let test = document.getElementById("myNav2").offsetWidth;
      if(test === 0) {
        closeNav();
      }
    }
  });

  function cell(header , body){
    return(
      <div className='popUpcellWrapper'>  
        <div className='popUpCellHeader'>{header}: </div>
        <div className='popUpCellBody' >{body}</div>
      </div>
    )
  }

  return ( 
    <div id="myNav" className="overlay">
      <span className="closebtn" onClick={()=> closeNav()}>X</span>
      <PopUpImg img={image}/>
      <div className="overlay-content">
        <h2 className='popUpHeader'>
          <FontAwesomeIcon className='popUpHeaderIcon' icon={faBookReader} />  {props.propa.Name}
        </h2>
        <div className='popUpWrapper'>
          <div className='popUpImagesSectionWrapper'>
            <div className='popUpImagesSectionContent'>
              {images.map((imgSrc, index) => {
                return (
                  <div className='popUpImages' onClick={()=>showimg(images,index)}  key={index} >
                    <img className='popUpimage' src={imgSrc.img} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
          <div className='popUpRightBarWrapper'>
            {cell('Client' , props.propa.Client)}
            <div className='popUpRightBarContent'>  
              <div className='rightBarLinkHeader'>Link: </div>
              <div>
                <a className='rightBarLink' href={props.propa.link} target="_blank"  rel="noreferrer">
                  {props.propa.linkName}
                </a>
              </div>
            </div>

            {/* tehnologiesSplited */}
            <div className='tehnologiesWrapper'>
              <div className='tehnologiesHeader'> Tehnologies: </div>
              <div className='tehnologiesCellWrapper'>
                {tehnologiesSplited.map((lineOfText, index) => {
                  return (
                    <div className='lineOfText' key={index} >
                      <FontAwesomeIcon className='popUpIcons' icon={faDotCircle } />
                      {' '}
                      { lineOfText}
                    </div>
                  )
                })}
              </div>
            </div>
            {cell('GitHub' , props.propa.pull)}
            {cell('Description' , splitedDescription)}
            {/* Responsobilities */}
            <div className='featuresHeader'> Main Features: </div>
            <div className='featuresWrapper'>
              {Responsibilities.map((lineOfText, index) => {
                return (
                  <p className='lineOfText' key={index}>
                    <FontAwesomeIcon className='featuresIcons' icon={faCheck }/>
                    {' '}
                    { lineOfText}
                  </p>
                )
              })}
           </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PopUp
