import {Homepage} from '../views/Homepage'
import {Contacts} from '../views/Contacts'
import {Work} from '../views/Work'
import {Education} from '../views/Education'
import {Skills} from '../views/Skills'
import {Portfolio} from '../views/Portfolio'

const Router = () => (
    <div>
        <Homepage />
        <Work />
        <Education />
        <Skills />
        <Portfolio />
        <Contacts />
    </div>
)

export default Router