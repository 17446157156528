import React from 'react'
import './css/homePage.scss'
import {Link} from "react-scroll";
import {textSpliter} from './js/textSpliter.js'
const Homepage = () => {
  const mainPageText = `
    I am a second-year student with a solid full-stack web development background and a passion for coding, software, and game development. 
    I have a collaborative spirit and an eagerness to expand my knowledge.

    I am currently pursuing a degree in game & software development and seeking an opportunity to challenge and enhance my abilities.

    My Interests:
    
    Game Development: Working on personal projects using Unreal Engine 5 and the GTA 5 Rage engine.

    Sports Activities: Engaging in Gym-Calisthenics, Roller Skating, and Volleyball.

    Music: Playing the guitar.

    Board and Video Games: Enjoying D&D-style board games and various PC RPGs such as Gothic, Divinity, NWN, BG3.

 `;
 let splitedText = textSpliter(mainPageText, '\n');

  return (
    <div id="homePage" className="homePage " style={{backgroundImage: 'url("./bg.jpg")',}}>
      <div className='mainPageAboutWrapper'>
        <h1 className='mainPageHeader'>JUNIOR WEB DEVELOPER</h1>
        <div className='aboutText'>
          {splitedText}
        </div>
        <Link to='skills' spy={true} hashSpy={true} smooth={true} offset={-50} duration={500}> 
          <button className='mainPageSkillBtn'>SKILLS</button>
        </Link>
      </div>
    </div>
  )
}
export {Homepage}