import './css/logoArea.scss'

function LogoArea() {
  return (
    <div className="LogoArea">
      <div className='logoImg'  style={{backgroundImage: 'url("./logo.jpg")'}}></div>
        <p>Leonids Gurockins</p>
    </div>
  )
}

export default LogoArea